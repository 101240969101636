import {get, post, put, del, getExel} from './base'
import * as qs from "qs";

/**
 * 获取直播会议列表
 * @param data
 * @returns {Promise<* | void>}
 */
export function liveMettingSpecials(data){
    return get("/api/admin/liveMettingSpecials",data)
}

/**
 * 新增
 * @param data
 * @returns {Promise<unknown>}
 */
export function liveMettingSpecialsPost(data){
    return post("/api/admin/liveMettingSpecial",data)
}

/**
 * 修改
 * @param meetingCode
 * @param data
 * @returns {Promise<unknown>}
 */
export function liveMettingSpecialsPut(meetingCode,data){
    return put("/api/admin/liveMettingSpecial/"+meetingCode,data)
}

/**
 * 获取系列会直播间
 * @param meetingCode
 * @returns {Promise<* | void>}
 */
export function getLiveRoom(meetingCode,data){
    return get("/api/admin/liveMeet?meetingCode="+meetingCode,data)
}
export function liveRoom(id,data){
    return get("/api/admin/liveMeet?id="+id,data)
}
/**
 * 添加直播间
 * @param data
 * @returns {Promise<* | void>}
 */
export function postLiveRoom(data){
    return post("/api/admin/insLiveStream",data)
}

/**
 * 修改直播间
 * @param data
 * @returns {Promise<* | void>}
 */
export function putLiveRoom(data){
    return put("/api/admin/updLiveStream",data)
}

/**
 * 删除会议
 * @param meetingCode
 * @returns {Promise<unknown>}
 */
export function delLive(meetingCode){
    return del("/api/admin/liveMettingSpecial/"+meetingCode)
}

/**
 * 查询直播间设置功能类型字典
 * @returns {Promise<* | void>}
 */
export function selectAllDataStreamFunction(){
    return get("/api/admin/selectAllDataStreamFunction")
}

/**
 * 获取直播间设置功能列表
 * @param streamId
 * @returns {Promise<* | void>}
 */
export function streamFunc(streamId){
    return get("/api/admin/streamFunc/"+streamId)
}

/**
 * 新增直播间设置功能
 * @param data
 * @returns {Promise<* | void>}
 */
export function postStreamFunc(data){
    return post("/api/admin/streamFunc",data)
}

/**
 * 修改直播间设置功能
 * @param data
 * @returns {Promise<* | void>}
 */
export function putStreamFunc(data){
    return put("/api/admin/streamFunc",data)
}

/**
 * 删除直播间设置功能
 * @param id
 * @returns {Promise<* | void>}
 */
export function delStreamFunc(id){
    return del("/api/admin/streamFunc/"+id)
}

/**
 * 直播间功能设置栏目上移下移
 * @param type 上移up 下移down
 * @param id   列表id
 * @returns {Promise<* | void>}
 */
export function streamFuncUpOrDown(type,id){
    return put("/api/admin/streamFuncUpOrDown/"+type+'/'+id)
}

/**
 * 修改直播间
 * @param data
 * @returns {Promise<unknown>}
 */
export function updLiveStream(data){
    return put("/api/admin/updLiveStream",data)
}

/**
 *获取云展厅列表
 * @param funcId
 * @returns {Promise<unknown>}
 */
export function getByFuncId(funcId,name){
    return get("/api/admin/getStreamGalleryByFuncId/"+funcId+'?title='+name)
}

/**
 * 云展厅列表上移下移
 * @param type
 * @param id
 * @returns {Promise<unknown>}
 */
export function liveStreamGalleryUpOrDown(type,id){
    return put("/api/admin/liveStreamGalleryUpOrDown/"+type+'/'+id)
}

/**
 * 新增云展厅
 * @param data
 * @returns {Promise<unknown>}
 */
export function postInsertLiveStreamGallery(data){
    return post("/api/admin/insertLiveStreamGallery",data)
}

/**
 * 云展厅删除
 * @param id
 * @returns {Promise<unknown>}
 */
export function deleteLiveStreamGallery(id){
    return del("/api/admin/deleteLiveStreamGallery/"+id)
}
/**
 * 获取直播间讨论评论列表
 * @param roomId
 * @returns {Promise<unknown>}
 */
export function getLiveChatRecord(roomId,message,data){
    return get("/api/admin/liveChatRecord?roomId="+roomId+'&message='+message,data)
}

/**
 * 获取发言人信息和在当前直播间的评论
 * @param streamId
 * @param memberId
 * @returns {Promise<unknown>}
 */
export function memberAndChat(streamId,memberId,content){
    return get("/api/admin/memberAndChat?streamId="+streamId+'&memberId='+memberId+'&content='+content)
}


/**
 * 设置禁言
 * @param liveChatIds
 * @returns {Promise<unknown>}
 */
export function insertShutUp(data){
    return post("/api/admin/insertShutUp",data)
}

/**
 * 设置黑名单
 * @param data
 * @returns {Promise<unknown>}
 */
export function insertBlackList(data){
    return post("/api/admin/insertBlackList",data)
}


/**
 * 删除直播间评论
 * @param liveChatIds
 * @returns {Promise<unknown>}
 */
export function liveChatRecord(liveChatIds){
    return del("/api/admin/liveChatRecord?liveChatIds="+liveChatIds)
}
/**
 * 获取直播间回放列表
 * @param data
 * @returns {Promise<unknown>}
 */
export function liveStreamVideos(data){
    return get("/api/admin/liveStreamVideos",data)
}

/**
 * 添加直播间回放
 * @param data
 * @returns {Promise<unknown>}
 */
export function postLiveStreamVideos(data){
    return post("/api/admin/liveStreamVideo",data)
}

/**
 * 修改直播间回放
 * @param data
 * @returns {Promise<unknown>}
 */
export function putLiveStreamVideos(data){
    return put("/api/admin/liveStreamVideo",data)
}

/**
 * 删除回放
 * @param id
 * @returns {Promise<unknown>}
 */
export function delLiveStreamVideos(id){
    return del("/api/admin/liveStreamVideo/"+id)
}

/**
 * 回放上移下移
 * @param type
 * @param id
 * @returns {Promise<unknown>}
 */
export function liveStreamVideosUpDown(type,id){
    return put("/yun/liveStreamVideo/UpOrDown?type="+type+'&id='+id)
}

/**
 * 通过id获取介绍和企业海报内容
 * @param id
 * @returns {Promise<unknown>}
 */
export function streamFuncById(id){
    return get("/api/admin/streamFuncById/"+id)
}

/**
 * 解除黑名单
 * @param data
 * @returns {Promise<unknown>}
 */
export function putRemoveBlack(data){
    return put("/api/admin/removeBlack",data)
}

/**
 * 解除禁言
 * @param data
 * @returns {Promise<unknown>}
 */
export function removeShutUp(data){
    return put("/api/admin/removeShutUp",data)
}

/**
 * 修改开场广告
 * @param data
 * @returns {Promise<unknown>}
 */
export function updMeetingBeginImg(data){
    return put("/api/admin/updMeetingBeginImg",data)
}

/**
 * 获取直播间悬浮功能信息
 * @param streamId
 * @returns {Promise<unknown>}
 */
export function getStreamFunXf(streamId){
    return get("/api/admin/getStreamFunXf/"+streamId)
}

/**
 * 设置悬浮功能
 * @param data
 * @returns {Promise<unknown>}
 */
export function putStreamFunXf(data){
    return put("/api/admin/updStreamFunXf",data)
}
