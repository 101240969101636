<template>
  <div>
    <a-modal
        :zIndex="3000"
      title="图片素材列表"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="1000"
    >
      <div class="modalDiv">
        <!-- 左 -->
        <div style="border-right: 2px solid #e9e9e9">
          <div class="menu" id="menu">
            <a-menu mode="inline" v-model="selectId" @click="handleClick">
              <a-menu-item key="1"> 全部图片 </a-menu-item>
<!--              <a-menu-item key="2">我的收藏</a-menu-item>-->
              <a-menu-item key="-1">默认分类</a-menu-item>
              <a-menu-item
                v-for="(item, index) in topData"
                :key="item.id"
                v-model="typeid"
              >
                {{ item.title }}
              </a-menu-item>
              <a-menu-item>
                <a-input
                  autocomplete="off"
                  id="addMenu"
                  placeholder="请输入"
                  v-show="addInput"
                  v-model="addTitle"
                  @pressEnter="addMenuTitlle"
                ></a-input>
              </a-menu-item>
              <!-- <a-menu-item class="addMenu">
              <div @click="addMenu">
                <span>新增栏目</span>
              </div>
            </a-menu-item> -->
            </a-menu>
          </div>
          <!--            <div @click="addMenu" class="addMenu">-->
          <!--              <span>新增栏目</span>-->
          <!--            </div>-->
        </div>
        <!-- 右 -->
        <div class="content-list">
          <!-- 顶部 -->
          <div class="content-list-top">
            <div>
              <a-button
                style="margin-left: 1%; background-color: #1890ff; color: white"
              >
                <a-icon type="vertical-align-top" />
                上传图片
                <input
                  @change="up_iconList"
                  id="files"
                  multiple
                  ref="batchImg"
                  style="
                    position: absolute;
                    top: 0;
                    opacity: 0;
                    width: 140px;
                    height: 35px;
                    left: 0;
                  "
                  accept="image/*"
                  type="file"
                />
              </a-button>
            </div>
            <div>
              <a-input-search
                allowClear
                style="width: 250px"
                placeholder="请输入图片名称"
                enter-button="搜索"
                v-model="imgName"
                @search="search"
              />
            </div>
          </div>

          <a-spin :spinning="spin">
            <!-- 图片 -->
            <div class="content-two-list-card">
              <a-row :gutter="16" type="flex" justify="start" align="bottom">
                <div
                  v-if="picture.length == 0"
                  style="
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                  "
                >
                  <NullDataPrompt message="暂无图片"></NullDataPrompt>
                </div>

                <a-col
                  v-else
                  :span="6"
                  v-for="(item, index) in picture"
                  :key="item.id"
                  style="margin-bottom: 2%"
                >
                  <label>
                    <div
                      class="item"
                      @mouseenter="showYuan(index)"
                      @mouseleave="unshow"
                      :class="{ selected: item.isChoose }"
                    >
                      <!-- 图片 -->

                      <div style="width: 100%; height: 75%" id="myimg">
                        <img
                          :src="item.url"
                          style="width: 100%; height: 100%;background-color: rgba(0,0,0,.85);"
                        />
                      </div>

                      <!-- 选择框 -->
                      <div
                        class="checkbox"
                        v-show="index === checkIndex ? true : false"
                      >
                        <input
                          v-if="info"
                          type="radio"
                          v-model="checkList1"
                          :value="item"
                          style="width: 15px; height: 15px"
                          id="target"
                          @change="hhh($event, item)"
                        />
                        <input
                          v-else
                          type="checkbox"
                          v-model="checkList"
                          :value="item"
                          style="width: 15px; height: 15px"
                          id="target"
                          @change="hhh($event, item)"
                        />
                      </div>

                      <div
                        class="dropdown"
                        v-show="index === selectIndex ? true : false"
                      >
                        <a class="dropdown-button">
                          <a-icon
                            type="ellipsis"
                            style="font-size: 26px; color: rgb(167, 160, 160)"
                          />
                        </a>
                        <div class="dropdown-menu">
                          <label for="addLabel"
                            ><a @click="changeName(index, item)"
                              >重命名</a
                            ></label
                          >
                          <a @click="changeCategory(item)">移动分类</a>
                          <a @click="del(item)">删除</a>
                        </div>
                      </div>
                      <!-- 名字 -->
                      <div
                        style="
                          width: 98%;
                          font-size: 14px;
                          font-weight: 550;
                          color: rgba(0,0,0,.85);
                          margin-top: 4px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;">
                        <div
                          v-if="index == inputshow ? false : true"
                          style="
                            width: 98%;
                            font-size: 14px;
                            font-weight: 550;
                            color: black;
                            margin-top: 1px;
                            margin-left: 7px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          {{ item.name }}
                        </div>
                        <div
                          v-if="index == inputshow ? true : false"
                          style="width: 100%; height: 50%"
                        >
                          <a-input
                            v-if="index == inputshow ? true : false"
                            :placeholder="item.name"
                            @pressEnter="changeimgName(item)"
                            @blur="change"
                            v-model="pictureName"
                            id="addLabel"
                          >
                          </a-input>
                        </div>
                      </div>
                      <!-- 时间 -->
                      <div
                          style="
                        width: 100%;
                        height: 10%;
                        position: absolute;
                        bottom: 0;
                      "
                      >
                        <p
                            style="font-size: 12px; padding: 2px 7px;color: rgba(0,0,0,.85);"
                        >
                          {{ item.createdTime }}
                        </p>
                      </div>
                    </div>
                  </label>
                </a-col>
              </a-row>
            </div>

            <!-- 分页 -->
            <div
              style="
                display: block;
                width: 100%;
                height: 50px;
                text-align: center;
              "
            >
              <a-pagination
                :current="curPage"
                :total="total"
                :pageSize="pageSize"
                show-quick-jumper
                @change="PageNOchange"
                style="display: inline-block"
              />
            </div>
          </a-spin>
        </div>

        <!-- 移动分组弹窗 -->
        <a-modal
          :visible="visible1"
          title="选择分类"
          @ok="moveOk"
          width="30%"
          @cancel="moveCancel"
          style="position: fixed; z-index: 999999"
        >
          <a-radio-group
            button-style="solid"
            :default-value="categoryId"
            :key="categoryId"
            style="padding: 10px 10px 30px 10px"
          >
            <a-radio-button :value="-1" :key="-1" @click="handle(-1)"
              >默认分类</a-radio-button
            >
            <a-radio-button
              :value="item.id"
              v-for="item in topData"
              :key="item.id"
              @click="handle(item.id)"
            >
              {{ item.title }}
            </a-radio-button>
          </a-radio-group>
        </a-modal>

        <!-- 删除 -->
        <a-modal
            :zIndex="3500"
          :visible="delVisible"
          @ok="deleteOk"
          @cancel="delCancel"
          :width="300"
          :height="20"
        >
          <p style="font-size: 18px">确认删除图片？</p>
        </a-modal>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getManagement,
  AddManagement,
  getPicture,
  changePicture,
  InsertImg,
  delPicture,
} from "../../service/columnmanageapi";
import { postImgList } from "@/service/document_api";
import NullDataPrompt from "../NullDataPrompt.vue";
export default {
  name: "pictureDialog",
  components: { NullDataPrompt },
  data() {
    return {
      spin: false,
      visible1: false, //移动分组弹窗
      topData: [],
      addTitle: "",
      addInput: false,
      picture: [],
      curPage: 1, //当前页
      total: 0, //总数
      pageSize: 8,
      iconList: [],
      typeid: "",
      imgName: "",
      checkIndex: -1,
      checkboxShow: -1, //选框
      selectIndex: -1, //圆孔显示
      // boxShow: -1, //操作框显示
      inputshow: -1, //输入框显示
      pictureName: "",
      category: {},
      checkList: [],
      checkList1: {},
      categoryId: "",
      selectId: ["1"],
      menuShow: false,
      delVisible: false,
      delete: {},
      isMyCollection: "",
      isKefu:null
    };
  },
  props: {
    // 接收父组件传递的参数
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    this.isKefu = userInfo.isKefu;
    this.load();
  },
  methods: {
    // handleDeletePop() {
    //   this.menuShow = true;
    // },

    // 类型
    load() {
      this.spin = true;
      getManagement({
        type: "img",
        curPage: 1,
        pageSize: 9999,
      }).then((res) => {
        this.topData = res.data;
        this.spin = false;
      });
      getPicture({
        columnId: this.typeid,
        name: this.imgName,
        startTime: this.startTime,
        endTime: this.endTime,
        curPage: this.curPage,
        pageSize: this.pageSize,
        isMyCollection: this.isMyCollection,

        memberId:this.isKefu ? this.$route.query.memberId : null  //客服上传图片需要传用户id
      }).then((res) => {
        this.picture = res.data;
        this.total = res.count;
        this.spin = false;
      });
    },
    handleClick(e) {
      this.curPage = 1;
      this.checkList = [];
      if (e.key === "1") {
        this.typeid = "";
        this.isMyCollection = "";
      } else if (e.key === "2") {
        this.typeid = "";
        this.isMyCollection = 1;
      } else {
        this.typeid = e.key;
        this.isMyCollection = "";
      }
      this.load();
    },
    handleCancel() {
      this.picture.forEach((item) => {
        item.isChoose = false;
      });
      this.load();
      this.curPage = 1;
      let flag = false;
      this.addInput = false;
      this.$emit("closeMain", flag); //子组件向父组件传值(开关)
      this.selectId = ["1"];
      this.typeid = "";
      this.load();
      document.getElementById("menu").scrollTop = 0;
      this.checkList1 = {};
      this.checkList = [];
    },

    handleOk() {
      this.picture.forEach((item) => {
        item.isChoose = false;
      });
      this.curPage = 1;
      let flag = false;
      this.$emit("closeMain", flag); //子组件向父组件传值（开关）
      if (this.info == true) {
        this.checkList.push(this.checkList1);
      }

      this.$emit("checkList", this.checkList); //子组件向父组件传值(勾选的图片)
      this.checkList = [];
      this.selectId = ["1"];
      this.typeid = "";

      this.load();
      document.getElementById("menu").scrollTop = 0;
    },
    // 新增栏目回调
    addMenuTitlle() {
      var input = /^[\s]*$/;
      if (!input.test(this.addTitle)) {
        let data = {
          type: "img",
          title: this.addTitle,
        };
        AddManagement(data).then((res) => {
          //添加栏目回调
          if (res.code == 200) {
            this.load();
            this.$message.success("新增成功");
            document.getElementById("menu").scrollTop = 0;
            // this.visible = false;
            this.addInput = false;
            this.addTitle = "";
            this.$emit("addMenu", data); //子组件向父组件传值（新增的栏目）
          } else {
            this.$message.warning("新增失败");
            this.load();
          }
        });
      } else {
        this.$message.warning("请输入正确的类别");
      }
    },

    // 点击添加栏目
    addMenu() {
      this.addInput = true;
      this.$nextTick(function () {
        document.getElementById("addMenu").focus();
      });
    },

    // 改变页码
    PageNOchange(curPage) {
      this.curPage = curPage;
      this.checkList = [];
      this.checkList1 = {};
      this.load();
    },
    // 搜索
    search() {
      this.curPage = 1;
      this.load();
    },
    // 鼠标经过
    showYuan(index) {
      this.selectIndex = index;
      this.checkIndex = index;
      // console.log("进");
    },
    //鼠标移开
    unshow() {
      // console.log("出");
      this.selectIndex = -1;
      this.checkIndex = -1;
    },

    // 获取checked状态
    hhh(e, item) {
      if (this.info) {
        //info===true 时为单选
        this.picture.forEach((item) => {
          item.isChoose = false;
        });
      }
      if (e.target.checked) {
        item.isChoose = true;
      } else {
        // this.checkIndex = -1;
        item.isChoose = false;
      }
    },
    change() {
      this.pictureName = "";
      this.inputshow = -1;
      this.load();
    },
    changeboxShow(index) {
      this.boxShow = index;
    },
    // 重命名
    changeName(index, item) {
      this.pictureName = item.name;
      this.inputshow = index;
      this.$nextTick(function () {
        document.getElementById("addLabel").focus();
      });
    },

    // 输入框确定回调
    changeimgName(item) {
      changePicture({
        id: item.id,
        name: this.pictureName,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功");
          this.inputshow = -1;
          this.load();
          this.pictureName = "";
        }
      });
    },
    // 打开删除
    del(item) {
      this.delete = item;
      // console.log(item);
      this.delVisible = true;
    },
    // 删除确定回调
    deleteOk() {
      delPicture(this.delete.id).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.load();
          this.delVisible = false;
        } else {
          this.$message.warning("删除失败");
          this.delVisible = false;
        }
      });
    },
    // 删除取消回调
    delCancel() {
      // this.checkList = [];
      this.$message.warning("取消删除");
      this.load();
      this.delVisible = false;
    },
    // 点击移动分组回调
    changeCategory(item) {
      this.visible1 = true;
      this.category.id = item.id;
      this.categoryId = item.columnId;
    },
    // 弹出框按钮回调
    handle(id) {
      this.category.columnId = id;
    },
    // 弹出框确定
    moveOk() {
      changePicture({
        id: this.category.id,
        columnId: this.category.columnId,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功");
          this.visible1 = false;
          this.load();
          this.categoryId = "";
        } else {
          this.$message.warning("修改失败");
          this.visible1 = false;
          this.load();
          this.categoryId = "";
        }
      });
    },
    // 弹出框取消
    moveCancel() {
      this.categoryId = "";
      this.visible1 = false;
      this.load();
    },
    // changeList(item) {
    //   if (!item.isChoose) {
    //     item.isChoose = true;
    //     this.checkListList.push(item.id);
    //   } else {
    //     item.isChoose = false;
    //     let index = this.checkList.indexOf(item.id);
    //     if (index > -1) {
    //       this.checkList.splice(index, 1);
    //     }
    //   }
    // },
    // 选择的图片
    // onChange(item) {
    //   this.checkList.push(item);
    // },
    // 批量上传图片
    async up_iconList() {
      this.spin = true;
      const fileList = document.getElementById("files").files;

      let formData = new FormData();
      console.log('几张图片',fileList.length)
      for (const file of fileList) {
        console.log('进行了几次')
        let picture = {};

        let str = file.name;
        let index = str.indexOf("."); // 获取元素在字符串中的索引位置
        let result = str.substring(0, index); // 使用substring()方法截取从0到index的子字符串

        picture.name = result;
        formData.append("file", file);
        const image = await postImgList(formData);
        if (image.code === 0) {
          for (const images of image.data.url) {
            picture.url = images;
          }

          this.iconList.push({
            url: picture.url,
            name: picture.name,
            columnId: this.typeid,
            isMyCollection: this.isMyCollection,
            memberId:this.isKefu ? this.$route.query.memberId : null  //客服上传图片需要传用户id
          });
        }
        InsertImg(this.iconList).then((res) => {
          this.spin = false;
          if (res.code == 0) {
            this.$message.success("上传成功");
            this.load();
            this.iconList = [];
          } else {
            this.$message.warning("上传失败");
            this.load();
            this.iconList = [];
          }
        });
      }
      document.getElementById('files').value = null;
    },
  },
};
</script>

<style scoped lang="scss">
.ant-col-6 {
  width: 25%;
}
.modalDiv {
  width: 100%;
  display: flex;
  height: 510px;
  margin-top: -24px;
  margin-left: -24px;
  // border-right: 2px solid #467ac9;
  .menu {
    width: 150px;
    border: none;
    position: relative;
    height: 413px;
    // background-color: #1890ff;
    overflow-y: scroll;
    // border-right: 2px solid #467ac9;
  }
  .addMenu {
    position: absolute;
    bottom: 70px;
    left: 15px;
    display: inline-block;
    display: flex;
    width: 11%;
    height: 40px;
    font-size: 17px;
    border: 3px dashed #edeff2;
    cursor: pointer;
    span {
      margin: auto;
      color: #1890ff;
    }
  }
  .content {
    width: 100%;
    border-left: 1px solid #e7e7e7;
    .content-top {
      height: 200px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      .top-icon {
        width: 10%;
        text-align: center;
        .icon {
          line-height: 200px;
          font-size: 40px;
          color: #929292;
          cursor: pointer;
        }
      }
      .imgList {
        width: 80%;
        display: flex;
        margin-top: 20px;
        .imgD {
          height: 160px;
          width: 150px;
          margin-right: 30px;
        }
      }
    }
    // .content-list {
    //   min-height: 490px;
    //   overflow-y: scroll;
    //   overflow-x: hidden;
    //   position: relative;
    //   // display: flex;
    //   .content-list-top {
    //     width: 98%;
    //     display: flex;
    //     justify-content: space-between;
    //     margin: 20px 0 0 0;
    //     // background-color: #45a5e6;
    //   }

    //   .content-two-list-card {
    //     display: block;
    //     width: 100%;
    //     height: 470px;
    //     // margin-top: 20px;
    //     // margin-left: 10px;
    //   }
    // }
  }
}
.content-list {
  margin-right: -24px;
  margin-left: 0.5%;
  width: 100%;
  .content-list-top {
    width: 98%;
    display: flex;
    margin: auto;
    justify-content: space-between;

    margin-left: 2%;
    margin-top: $LineSpacing;
    margin-bottom: $LineSpacing;
  }
  .content-two-list-card {
    width: 98%;
    height: 410px;
    margin-left: 2%;
    position: relative;
  }
}
.item {
  position: relative;
  width: 100%;
  height: 190px;
  display: inline-block;
  border: 1.5px solid #edeff2;
  border-radius: 8px;

  .checkbox {
    position: absolute;
    top: 1.5%;
    left: 1.5%;
  }
  .yuan {
    position: absolute;
    top: 1.5%;
    right: 1.5%;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    z-index: 0;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .box {
    position: absolute;
    top: 19%;
    right: 4%;
    width: 40%;
    height: 40%;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
    span {
      display: inline-block;
      background: #fff;
      width: 100%;
      height: 33.4%;
      cursor: pointer;
    }
  }
}
.selected {
  position: relative;
  width: 100%;

  height: 190px;

  display: inline-block;

  border: 3px solid #5aaaf5;
  border-radius: 5px;

  .checkbox {
    position: absolute;
    top: 1.5%;
    left: 1.5%;
  }
  .yuan {
    position: absolute;
    top: 1.5%;
    right: 1.5%;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    z-index: 0;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .box {
    position: absolute;
    top: 19%;
    right: 4%;
    width: 40%;
    height: 40%;
    background: #fff;
    z-index: 999;
    border-radius: 5px;
    span {
      display: inline-block;
      background: #fff;
      width: 100%;
      height: 33.4%;
      cursor: pointer;
    }
  }
}
/* 按钮样式 */
.dropdown-button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: white;
  text-align: center;
  line-height: 40px;
}

/* 下拉菜单 */
.dropdown-menu {
  /* 默认不显示 */
  display: none;
  /* 设置宽度 */
  width: 80px;
  /* 设置边框 */
  // border: 2px solid #e5f1fb;
  margin-top: 35px;
  border-radius: 5px;
  background-color: white;
}
/* 鼠标悬停时，显示菜单区域 */
.dropdown:hover .dropdown-menu {
  display: block;
}
/* 链接样式 */
.dropdown-menu a {
  /* 换行 */
  display: block;

  color: $topNameColor;
  /* 增大间距 */
  padding: 5px 8px;
  /* 去掉下划线 */
  text-decoration: none;
}

/* 整个下拉菜单 */
.dropdown {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 5px;
}
.ant-radio-button-wrapper {
  border-radius: 5px;
  border: none;
  &::before {
    display: none; // 隐藏分界线
  }
}
::v-deep .ant-radio-button-wrapper::before {
  display: none;
}
::v-deep .ant-modal-footer {
  margin-top: -24px;
}
// .modal {
//   border-bottom: none !important;
// }

// .modal {
//   border-top: none !important;
// }
</style>
