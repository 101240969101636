<template>
<div style="width: 100%;text-align: center">
  <!--空数据提示-->
  <admin_con class="iconNull" type="icon-kongshuju"/>
  <div class="messageNull">{{message}}</div>
</div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";

export default {
  name: "NullDataPrompt",
  components:{
    admin_con: admin_icon,
  },
  props: ['message'],
}
</script>

<style scoped>
.iconNull{
  font-size: 70px;margin-top: 100px;
}
.messageNull{
  color: #cecccc;margin-top: 10px;
}
</style>
